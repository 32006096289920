/* eslint-disable global-require */
import { contactConfig } from "@energylab/shared-components/constants/config/contactConfig";
import { galleryConfig } from "@energylab/shared-components/constants/config/galleryConfig";
import { profileConfig as defaultProfileConfig, ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { resetPasswordConfig } from "@energylab/shared-components/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { getRoutes } from "@energylab/shared-components/routes";
import * as environment from "environment";
import { footerConfig } from "@energylab/shared-components/constants/config/footerConfig";
import { REDIRECTS, ROUTES } from "./routes";
import { manualActivityConfig } from "./manualActivityConfig";
import { profileStructure, registerStructure } from "./profileAndRegistration";
import { navbarConfig } from "./navbarConfig";

const locales = ["nl", "fr"];
const defaultLocale = "fr";

const profileConfig: ProfileConfig = {
    ...defaultProfileConfig,
    structure: profileStructure,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "nationality",
        "locale"
    ]
}
// TODO: Change company logo
export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1650612032/Matexi/matexi_team-belgium_logo_loginpage.png";
const companyName = "Matexi | BOIC";

export const config: ConfigReducerState = {
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    profileConfig,
    navbarConfig,
    hideSocialLogin: true,
    registerConfig: {
        backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1649428839/matexi/brian-matangelo-gRof2_Ftu7A-unsplash.jpg",
        requireEmailValidation: true,
        structure: registerStructure
    },
    resetPasswordConfig,
    galleryConfig,
    footerConfig: {
        ...footerConfig,
        links: ["privacy", "disclaimer", "contact"],
        enlabLogo: undefined,
        companyLogo,
        companyName
    },
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    manualActivityConfig,
    referralConfig: {
        enabled: true
    },
    dashboardPlacesConfig: {
        articles: [],
        benefits: [],
        challenges: [],
        events: [],
        gallery: [],
        homePage: ["dashboard"],
        leaderBoard: [],
        mySpace: [],
        mySpaceActivities: [],
        profile: [],
        webShop: [],
        contact: ["contact_header"]
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: true,
        autoShowInput: true
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1649428839/matexi/brian-matangelo-gRof2_Ftu7A-unsplash.jpg",
        subjectOptions: [
            { key: "energylab", value: "energylab" }
        ],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName,
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "coin"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    notificationsConfig: {
        enabled: false
    },
    providers: [
        "strava",
        "garmin",
        "fitbit",
        "runkeeper",
        "pacer",
        "connect"
    ],
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        routes: {
            private: getRoutes(ROUTES)
        },
        redirects: REDIRECTS,
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "Matexi | BOIC",
        title: {
            nl: "Matexi | BOIC",
            fr: "Matexi | BOIC",
            en: "Matexi | BOIC"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "matexi",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    }
};
