import { FormElement, FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { minYearOld, password } from "environment";
import { SEX } from "@energylab/shared-components/utils/sex";
import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { locales } from "@energylab/shared-components/constants/config/routeConfig";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { registerStructure as defaultRegisterStructure } from "@energylab/shared-components/modules/register/registerForm/config";
import { NicknameWrapper } from "@energylab/shared-components/components/profile/profileForm/profileFormStyle";

const sexField: FormElement = {
    title: "form.sex",
    type: "radio",
    options: [
        { title: <FormatMessage id="male" />, value: SEX.male },
        { title: <FormatMessage id="female" />, value: SEX.female },
        { title: <FormatMessage id="intersexual" />, value: SEX.other }
    ],
    property: "sex",
    required: true
}

const birthDayField: FormElement = {
    title: "form.birthDate",
    type: "date",
    property: "birthDate",
    required: true,
    validationRules: [
        {
            validatorBuilder: checkMinBirthDateCreator(minYearOld)
        }
    ]
}

const nationalityField: FormElement = {
    title: "form.nationality",
    type: "select",
    options: nationalities.map(nat => (
        {
            title: <FormatMessage id={`nationality.${nat.code}`} />,
            value: nat.code
        }
    )),
    property: "nationality",
    required: true
}

const givenNameField: FormElement = {
    title: "auth.fields.givenName",
    placeholder: "auth.fields.givenName",
    type: "text",
    property: "givenName",
    required: true
}

const familyNameField: FormElement = {
    title: "auth.fields.familyName",
    placeholder: "auth.fields.familyName",
    type: "text",
    property: "familyName",
    required: true
}
const localeField: FormElement = {
    title: "form.language",
    type: "select",
    options: locales.map(locale => (
        {
            title: <FormatMessage id={`locale.${locale}`} />,
            value: locale
        }
    )),
    property: "locale",
    required: true
}

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                givenNameField,
                familyNameField
            ]
        },
        {
            title: "auth.fields.useNickname",
            type: "checkbox",
            property: "useNickname",
            styledComponent: NicknameWrapper
        },
        {
            placeholder: "auth.fields.nickname",
            type: "text",
            property: "nickname",
            disableWhen: {
                property: "useNickname",
                valueIs: false
            },
            requireWhen: {
                property: "useNickname",
                valueIs: true
            }
        },
        birthDayField,
        nationalityField,
        sexField,
        localeField
    ]
};


export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        defaultRegisterStructure.steps[0],
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                givenNameField,
                familyNameField,
                sexField,
                birthDayField,
                nationalityField,
                localeField
            ]
        },
        defaultRegisterStructure.steps[2]
    ]
};
